<template>
  <CenterLayout>
    <div class="text-center">
      <h3>Weiterleitung nicht gefunden :/</h3>
      <router-link
        class="btn btn-outline-primary"
        :to="{ name: 'Redirect' }"
      >
        Zurück
      </router-link>
    </div>
  </CenterLayout>
</template>

<script>
import CenterLayout from '@/layouts/CenterLayout'
import feathers from '@/api'
import store from '@/store'

export default {
  name: 'PushNotificationRedirect',
  components: { CenterLayout },
  async beforeRouteEnter (to, from, next) {
    try {
      const { redirectUrl, redirect } = await feathers.service('push-notification').patch(to.params.notificationId, {
        $push: { readBy: store.state.auth.user.user._id },
        read: true
      })
      if (redirect != null) next(redirect)
      else if (redirectUrl != null) location.replace(redirectUrl)
      else next()
    } catch (error) {
      console.error(error)
      next()
    }
  }
}
</script>

<style scoped lang="scss">

</style>
